const firebaseConfig = {
    apiKey: "AIzaSyBQT1wSdWVJAzwSofOgjB8dlX16abUHCu4",
    authDomain: "speed-networking-8db08.firebaseapp.com",
    databaseURL: "https://speed-networking-8db08-default-rtdb.firebaseio.com",
    projectId: "speed-networking-8db08",
    storageBucket: "speed-networking-8db08.appspot.com",
    messagingSenderId: "335195455266",
    appId: "1:335195455266:web:b5080ee5a36274de1aaf7b"
  };

  export default firebaseConfig