import react  from 'react'
import {Routes, Route} from 'react-router-dom'
import Event from './pages/Event';
import NotFound from './pages/NotFound';
function App() {

  return (
    <div className="App" >
    <Routes>
    <Route  exact path='/' element={<NotFound/>}/>

    <Route  path='/:eventId' element={<Event/>}/>
    </Routes>
            </div>
  );
}

export default App;
