import React, {useState, useEffect} from 'react'
import { useParams } from "react-router-dom";
import {getSite} from '../services/participants.service'
import InitialLoading from '../components/InitialLoading';
import Activity from '../components/Activity'
import Swal from 'sweetalert2'
import NotFound from './NotFound';




const Event= () =>{
    const [initialLoading, setInitialLoading] = useState(true)
    const { eventId } = useParams();
    const [eventDesign ,setEventDesign] = useState({})
    const [notFound, setNotFound] = useState(false)
    const [questions, setQuestions] = useState([])
   
    const getPageDetails = async() => { 
        try{ 
            const res = await getSite(eventId) 
            const data = res.data.response 
            setEventDesign(data.eventDesign)
            setQuestions(data.questions)
            setInitialLoading(false)
        }catch(err){ 
            console.log(err)
            setNotFound(true)
            setInitialLoading(false)
            
            if(!err.response){
                Swal.fire({
                    icon: 'error',
                    title: 'Something went wrong!',
                    
                  })

            }
        }
        }
    useEffect(()=>{
        getPageDetails()
        
    },[])
  return (
    <div>
        {initialLoading? <InitialLoading/> :<>
        
        {Object.keys(eventDesign) && !notFound? 
        <Activity questions={questions} eventId={eventId} style={eventDesign}/>        
        :
        <NotFound /> 
        }
        </>}
    </div>
  )
}

export default Event
