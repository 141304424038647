
import React from 'react'

export default function MoreIcon({color}) {
  return (
    <svg width="80px" height="80px" fill="none" xmlns="http://www.w3.org/2000/svg">
<g >
<path d="M48.2547 75.8288L26.5893 54.1634L30.5284 50.2242L48.2547 67.9505L65.981 50.2242L69.9202 54.1634L48.2547 75.8288ZM48.2547 56.133L26.5893 34.4675L30.5284 30.5283L48.2547 48.2546L65.981 30.5283L69.9202 34.4675L48.2547 56.133Z" fill={color} fill-opacity="0.7"/>
</g>

</svg>
  )
}


