import firebase from 'firebase'
import config from './config'

firebase.initializeApp(config)




export const getUserData = (id, update, setRound) => { 

 firebase.firestore().collection('users')  .doc(id).onSnapshot((snap)=> { 
        const userData = snap.data()
        update(userData)
        setRound(userData.round)
    },(err) => {
        console.log(err)
    })
}
