import React, {useState, useEffect, useRef}  from 'react'
import ReactLoading from 'react-loading';
import { getUserData} from '../firebase.js'
import Swal from 'sweetalert2'

import { login } from '../services/participants.service.js';
import MoreIcon from '../assets/MoreIcon.jsx';

const Activity = ({style, eventId, questions})=> {
  const [state, setState] = useState({})
  const [loggedIn, setLoggedIn] = useState(false)
  const [round, setRound] = useState(-1)
  const [tables, setTables] = useState([])
  const [loading,setLoading ] = useState(false)
  const [id, setId ] = useState('')
  const [ready, setReady] = useState(false)
  const [userData, setUserData] = useState({})
  const [popup, setPopup] = useState(false)
  const inputRef = useRef(null)
  const togglePopup = () => { 
    setPopup(!popup)
  }
  const handleChange = (e) =>{
    setState({...state, [e.target.name]: e.target.value})
  }

  const formatQuestions = (q) => { 
    const q1 = q.split('-n---t---n-')
    if(q1.length > 1 && ! q1.length < userData.track){ 
      return q1[userData.track - 1].split('-n-')

    }else if ( q1.length > 1  && q1.length < userData.track){ 
      return q1[q1.length - 1].split('-n-')

    }
    return q.split('-n-')

  }
  const getTables = async(email) => { 
    try{
     setLoading(true)
     email = email.replace(/ /g, '');
      const res = await login({email, eventId})
      console.log(res.data)
      const user = res.data.response.user
      localStorage.setItem('speedNetworkingEventId', eventId)

      localStorage.setItem('speedNetworkingEmail', email)
      localStorage.setItem('speedNetworkingId', user.id)
      setId(user.id)
      getUserData(user.id, setUserData, setRound)

      setLoading(false)
      setLoggedIn(true)
      
    }catch(err){ 
      console.log(err)
      setLoading(false)
     
      if(err.response){

        if(err.response?.status == 401){ 
          Swal.fire({
            icon: 'warning',
            title: 'You are not a participant!',
            text: 'Use the email that you singed on with.',
            confirmButtonColor: style.buttonBg,

          })
          
          localStorage.removeItem('speedNetworkingId')
          localStorage.removeItem('speedNetworkingEventId')
          localStorage.removeItem('speedNetworkingEmail')
          
        }else{
          Swal.fire({
            icon: 'error',
            title: 'Something went wrong!',
            confirmButtonColor: style.buttonBg,

          })
        }
      }else{
        Swal.fire({
          icon: 'error',
          title: 'Something went wrong!',
          confirmButtonColor: style.buttonBg,

        })
      }
      }
    }
  
  const handleSubmit = async (e) => {
    e.preventDefault()
    try{
      if(!state.email){ 
        Swal.fire({
          icon: 'error',
          title: 'Email is required',
          confirmButtonColor: style.buttonBg,

        })
        return
      }
      await getTables(state.email)
    }catch(err){ 
      console.log(err)
    }
  }

  const initial = async() =>{ 
    try{ 

      const email = localStorage.getItem('speedNetworkingEmail')
      const localEventId = localStorage.getItem('speedNetworkingEventId')
      if(email && localEventId == eventId){ 
        getTables(email)
      }
  }catch(err){ 
      setLoading(false)
  }
  }
  useEffect(() => {

    initial()
  }, [])

 
  return (
      <div className="App" style={{backgroundColor: style.bg}} >

   <style> 
              

                {`
                @font-face {
                  font-family: "CustomFont";
                  src: url(${style.font})  format("opentype");
                  font-weight: bold;
                  font-style: normal;
                }

                body{
                  font-family:  CustomFont , Helvetica !important;

                }
                `}
                  {` 

                    input{
                      font-family:  CustomFont , Helvetica !important;

                    } 
                    ::placeholder { 
                        color: ${style.inputPlaceholder}; 
                        font-family:  CustomFont , Helvetica !important;
                    }
                    .questions__container::-webkit-scrollbar-thumb {
                      background: ${style.questionBtn};
                      border-radius: 5px;
                      }
                    ` 
                } 
            </style> 
      <div className='header'>
      <div className='header__image__container' style={{width:style.logoWidth, marginTop:style.logoMarginTop}}>

        <img src={style.logo} className="header__image"/> 
      </div>
      </div>
      <div >
    {loading? 
      <div className='center__container'>

<ReactLoading type='bars' color={style.loading}/> 
  </div>
        :
        <>
      {!loggedIn? 
        <form onSubmit={handleSubmit} className={'form'}>
        <h2 className='input__title'  style={{color: style.title}}>Email</h2>
        <input id="input" ref={inputRef} onChange={handleChange}  style={{backgroundColor: style.inputBg, color:style.inputText }} placeholder="Email address" type='text' className='email__input' name="email" /> 
        <input type={'submit'}  className="submit__btn" style={{backgroundColor: style.buttonBg, color: style.buttonText }} /> 
        </form>
        : <> 
        {round< 0|| !userData.table? 
          <div className='center__container'>
            
           <div>
           <h2 className='waiting__title' style={{color: style.title}}>Please wait for next round</h2>
            
          <div className='waiting__loading'>
    <ReactLoading type='bars'  color={style.loading}/> 
             </div>
           </div>
  </div>
        :
        <div className='center_content'>
          <div className='page__container'>
          <div className="numbers__container">
          <div className="number__container">
          <h3 className='title' style={{color: style.title}}>Round</h3>
          <h3 className='number' style={{color: style.numbers}}>{round+1}</h3>

          </div>
          <div className="number__container">

          <h3 className='title' style={{color: style.title}}>Table</h3>
          <h3 className='number' style={{color: style.numbers}}>{userData.table} </h3>
          </div>
          </div>
<div className='question__section'>


</div>
{questions[round]? 

      <div className="popup__container"  style={{backgroundColor: style.popupBg, marginTop:popup?'-150px' :''}}  onClick={!popup?togglePopup:()=>{}} > 
{popup? 
<h1 onClick={togglePopup} style={{color: style.popupTitle}} className='popup__remove'>X</h1>
:''}

<h3 className='questions__title' style={{color: style.popupTitle}}>Questions for round {round+1}</h3>
{!popup?
<div className="more__icon__container">

  <MoreIcon color={style.questionBtn} /> 
</div>
 :''}
 {popup? 
  <div className="questions__container">
<ul>


{formatQuestions(questions[round]).map(question =><li className='question__content' style={{color: style.popupText}}>{question}</li> )}

</ul>
  </div>
  :''}
 </div>
:''}

          </div>

          
      
          </div>
        }
          </>}
        </>}

    </div>
        <div className='footer'> 
        <img src={style.pattern} className="footer__image"/> 
        </div>

        </div>
  );
}

export default Activity