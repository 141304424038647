import React from 'react'

import ReactLoading from 'react-loading';

 const InitialLoading= ()=>{
  return (
    <div>
     
         <div className='center__container'>
            
            <div>
             
           <div className='waiting__loading'>
     <ReactLoading type='bars'  color={'#000'}/> 
              </div>
            </div>
   </div>
  
    </div>
  )
}
export default InitialLoading