import React from 'react'
import hackyardLogo from '../assets/hackyardLogo.png'
import hackyardPattern from '../assets/hackyardPattern.png'

export default function NotFound() {
  return (
    <div>
    <div className='header'>
    <div className='hackyard__header__image__container'>

      <img src={hackyardLogo} className="header__image"/> 
    </div>
    </div>
       <div className='center__container'>
          
          <div>
           
         <div className='waiting__loading'>
          <h2 className='notfound__title' >Page Not Found</h2>
            </div>
          </div>
 </div>
 <div className='footer'> 
      <img src={hackyardPattern} className="footer__image"/> 
      </div>
  </div>  )
}
